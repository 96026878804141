import React, { useRef } from 'react';
import { getDomains, postChunks, postRun, getDownloadFile } from '../Api';
import { useState, useEffect, useCallback } from 'react';

import { Flex, Box, Spacer , Input, Container,  HStack, Skeleton, useBreakpointValue, 
    Tabs, TabList, TabPanels, Tab, TabPanel, Stack, Card, CardBody, CardHeader,
    Heading, Text, useToast, IconButton, Menu, MenuButton, Checkbox,
    Button, MenuList, MenuItem, Link, Image, LinkBox, LinkOverlay, Divider
} from '@chakra-ui/react';

import { RepeatIcon, ChevronDownIcon } from '@chakra-ui/icons'
import { IoIosSend, IoMdDownload } from 'react-icons/io';

import LoadingOverlay from '../components/LoadingOverlayComponent';
import { usePageColor } from '../contexts/ColorContext';

import {newTheme} from '../components/mdstyle'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";

import { format } from 'date-fns';
import { ko } from 'date-fns/locale'; 

const ChatbotPage = ({checkExpireTime}) => {

    const colors = usePageColor('chatbot');

    const toast = useToast();
    const inputRef = useRef(null);

    const [allDomainsData, setAllDomainsData] = useState({ result: [] });
    const [activedDomainIndex, setActivedDomainIndex] = useState(1);

    const [inputQuery, setInputQuery] = useState("");
    const [postRunTriggered, setPostRunTriggered] = useState(false);

    const [queryResponses, setQueryResponses] = useState('');
    const [chunkResponses, setChunkResponses] = useState({ results: [] });
    const [naverApiResponses, setNaverApiResponses] = useState([]);
    const [youtubeResponses, setYoutubeResponses] = useState([]);

    const [isBlogChecked, setIsBlogChecked] = useState(true);
    const [isNewsChecked, setIsNewsChecked] = useState(false);
    const [isHandlingEvent, setIsHandlingEvent] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);

    const [isQueryResponseShowed, setIsQueryResponseShowed] = useState(false);
    const [isChunkResponseShowed, setIsChunkResponseShowed] = useState(false);
    const [isAIResponseLoading, setIsAIResponseLoading] = useState(false);

    const handleCheckboxChange = (setter) => (e) => {
        setter(e.target.checked);
    }
    
    const togglePostRunWrapper = () => {
        if (!isHandlingEvent) {
            setIsHandlingEvent(true);
            handlePostRun();
            setTimeout(() => setIsHandlingEvent(false), 500);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const data = await getDomains();
                if (data.result === "No Token Sent") {
                    setAllDomainsData({ result: [] });
                } else {
                    setAllDomainsData(data);
                    setActivedDomainIndex(data.result[0]?.domain_id);
                    setInputQuery(data.result.find(domain => domain.domain_id === activedDomainIndex)?.default_prompt_text || "데이터 로딩 중...")
                }                
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '도메인 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        })();
    }, [toast, activedDomainIndex]);

    const handleGetChunks = useCallback(async (fileChunks) => {

        await checkExpireTime();

        if (fileChunks.file_ids && fileChunks.chunk_ids) {
            try {
                const data = await postChunks(fileChunks);
                setChunkResponses(data.results);
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            } 
        } else {
            return;
        }  

    }, [toast]);
    

    const handlePostRun = useCallback(async () => {

        await checkExpireTime();
    
        if (inputQuery === "") {
            toast({
                title: 'Failed',
                description: '질문을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        } else {
            setIsFormLoading(true);
            
            setQueryResponses(''); // 기존 AI답변 초기화
            setChunkResponses([]); // 기존 AI답변 청크 초기화
    
            setIsQueryResponseShowed(false);
            setIsChunkResponseShowed(false);
            setIsAIResponseLoading(true);
            setYoutubeResponses([]);
            setNaverApiResponses([])
            
            let docsOrNaver = '';

            if (isBlogChecked || isNewsChecked) {
                docsOrNaver = 'naver_api'
            
            } else {
                docsOrNaver = 'docs'
            }

            try {
                const stream = postRun(inputQuery, isBlogChecked, isNewsChecked, docsOrNaver);

                for await (let token of stream) {

                    if (token.query_response) {
                        
                        setIsQueryResponseShowed(true);
                        setIsChunkResponseShowed(true);
                        setIsFormLoading(false);
                        setIsAIResponseLoading(false);
                        
                        setQueryResponses(prev => prev + token.query_response)

                    } 
                    if (token.youtube_list) {
                        setYoutubeResponses(token.youtube_list);
                    }

                    if (token.file_ids && token.chunk_ids) {
                        let fileChunks = {
                            file_ids: token.file_ids,
                            chunk_ids: token.chunk_ids,
                            collection: token.collection
                        }
                        handleGetChunks(fileChunks);
                        
                    }

                    // [{title: '제목', link: '링크', pDate: '날짜'}, {}, {}]

                    if (token.title && token.link && token.pDate) {
                        let newResponses = [];

                        for (let i = 0; i < token.title.length; i++) {
                            let naverApiResponse = {
                                title: token.title[i],
                                link: token.link[i],
                                pDate: token.pDate[i],
                                description: token.description[i]
                            };
                            newResponses.push(naverApiResponse);
                        }

                        setNaverApiResponses(newResponses);
                        console.log(newResponses)
                    }


                    if (token.last_answer === "Y") {
                        setQueryResponses(token.query_response);
                        break;
                    }
                }
                setPostRunTriggered(true);
    
            } catch {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
    
            } finally {
                setIsFormLoading(false);
                setIsQueryResponseShowed(true);
                setIsAIResponseLoading(false);
            }
        }
    }, [inputQuery, isBlogChecked, isNewsChecked, toast, handleGetChunks, setPostRunTriggered])

    useEffect(()=>{
        if (postRunTriggered) {
            if (queryResponses.length < 5) {
                console.log("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
                setQueryResponses("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
            }
            setPostRunTriggered(false);
        } 
        
    }, [postRunTriggered, queryResponses, setPostRunTriggered])

    useEffect(() => {
        if (chunkResponses && Object.keys(chunkResponses).length > 0) {
            setIsChunkResponseShowed(true);
        }
    }, [chunkResponses]); 

    useEffect(()=>{
        setIsQueryResponseShowed(false);
        setIsChunkResponseShowed(false);
    }, [activedDomainIndex])

    const handleGetFileDownload = useCallback(async (fileId) => {
        try {
            await getDownloadFile(fileId)
        } catch {
            toast({
                title: 'Failed',
                description: '파일 다운로드 실패',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, []);

    const overflowTabFixed = useBreakpointValue({ base: false, md: true })
    return(
        <Container bg={colors.ContainerMainBg} maxW='100%' minH='95vh' size='container.3xl'>
            <Container
                maxW = 'container.xl' 
                bg={colors.ContainerMainBg}
                color={colors.ContainerPromptColor}
            >       
                    <Flex direction='column'>
                        <Container maxW='' position='relative' pb='10'>                            
                        <LoadingOverlay isLoading={isFormLoading} bgColor={colors.ContainerMainBg}/>
                            {overflowTabFixed && (<Tabs mt='10'>                            
                                <TabList>
                                {allDomainsData.result.map((domainData)=>(
                                    <Tab
                                    key = {domainData.domain_id}
                                    onClick={()=>{
                                        setActivedDomainIndex(domainData.domain_id)
                                        setInputQuery(domainData.default_prompt_text)
                                    }}
                                    >
                                        {domainData.domain_name}
                                    </Tab>
                                ))}
                                </TabList>
                            </Tabs>)}
                            {!overflowTabFixed && (
                                <Flex mt='7' >                                
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={colors.AssistantMessageBg} w="300" borderRadius='13px'>
                                            {allDomainsData.result[activedDomainIndex-1]?.domain_name || "데이터 로딩 중..."}
                                        </MenuButton>
                                        <MenuList>
                                            {allDomainsData.result.map((domainData)=>(
                                                <MenuItem
                                                    key = {domainData.domain_id}
                                                    onClick={()=>{
                                                        setActivedDomainIndex(domainData.domain_id)
                                                        setInputQuery(domainData.default_prompt_text)
                                                    }}
                                                    >
                                                    {domainData.domain_name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                    <Spacer />
                                    <HStack>                                                                                             
                                    </HStack>                                    
                                </Flex>
                            )}            
                            <Flex direction='column' >
                                <HStack mt='30' ml='15'>
                                    <Checkbox 
                                        isChecked={isBlogChecked}
                                        onChange={handleCheckboxChange(setIsBlogChecked)}
                                        display="none"
                                    >
                                        Blog
                                    </Checkbox>

                                    <Checkbox 
                                        isChecked={isNewsChecked}
                                        onChange={handleCheckboxChange(setIsNewsChecked)}
                                    >
                                        News 추가 검색
                                    </Checkbox>
                                </HStack>                                                                                                    
                                <HStack mt='8'>
                                <Input
                                    placeholder={"어떤 질문을 하고 싶으신가요? ex) 사내 규칙을 알려줘." || "데이터 로딩 중..."}
                                    borderRadius='13px'
                                    borderWidth='2px'
                                    onKeyDown={(e) => {
                                        if(e.key === 'Enter')                     
                                        // handlePostRun();    
                                        togglePostRunWrapper();                
                                    }}
                                    ref={inputRef}
                                    onChange={(e) => {setInputQuery(e.target.value);}}
                                    value={inputQuery}
                                />
                                <IconButton
                                    borderRadius='13px'
                                    onClick={() => {
                                        // handlePostRun();
                                        togglePostRunWrapper();
                                    }}
                                    icon={<IoIosSend />}
                                    colorScheme='blue'
                                />
                                <IconButton
                                    icon={<RepeatIcon />}
                                    onClick={()=>{
                                        setIsQueryResponseShowed(false);
                                        setIsChunkResponseShowed(false);
                                        setActivedDomainIndex(1);
                                        setInputQuery(allDomainsData.result[0]?.default_prompt_text || "데이터 로딩 중...")
                                    }}
                                    borderRadius='13px'
                                />
                                </HStack> 
                            </Flex>                             
                        </Container>
                        <Spacer/>            
                        <Container alignContent='center' maxW='' mt='10'>
                            <Box>
                                <Tabs colorScheme='green' >
                                    <TabList>
                                        <Tab>AI 답변</Tab>
                                    </TabList>
                                    <TabPanels mt ='4' borderRadius='13px'>
                                        {isAIResponseLoading && (
                                            <Stack pt='30px'>
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                                <Skeleton height='40px' />
                                            </Stack>
                                        )}  
                                        {isQueryResponseShowed && (
                                        <TabPanel>
                                            <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                            {queryResponses}
                                            </Markdown>                                                                             
                                        </TabPanel>
                                        )}
                                    </TabPanels>                             
                                </Tabs>                                
                            </Box>  

                            {youtubeResponses.length > 0 && (
                                <>
                                    {/* 구분자 */}
                                    <Divider mt="8" mb="4" borderColor="gray.300" />

                                    {/* YouTube List Title */}
                                    <Flex alignItems="center" mt='10'>
                                        <Image 
                                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b8/YouTube_Logo_2017.svg/640px-YouTube_Logo_2017.svg.png" 
                                            alt="YouTube Logo" 
                                            maxW="100%" 
                                            maxH="30px" 
                                            mr="4"
                                        />
                                    </Flex>

                                    {/* YouTube Videos List */}
                                    <Stack spacing='4' mt='5'>
                                        {youtubeResponses.map((video) => {
                                            const publishedDate = new Date(video.publishedAt);
                                            const isValidDate = !isNaN(publishedDate);
                                            return (
                                                <LinkBox as='article' key={video.webLinkUrl}>
                                                    <Card 
                                                        size='lg' 
                                                        direction={{ base: 'column', md: 'row' }} 
                                                        overflow='hidden' 
                                                        variant='outline'
                                                        cursor="pointer"
                                                    >
                                                        <Image 
                                                            src={video.thumImage} 
                                                            alt={video.title} 
                                                            objectFit="cover"
                                                            maxW={{ base: '100%', md: '300px' }} 
                                                            width="100%"
                                                        />
                                                        <Stack flex="1">
                                                            <CardBody>
                                                                <Heading size='md'>
                                                                    <LinkOverlay href={video.webLinkUrl} isExternal>
                                                                        {video.title}
                                                                    </LinkOverlay>
                                                                </Heading>
                                                                <Text mt='2' fontSize='md' color='gray.600'>
                                                                    채널: {video.channelTitle}
                                                                </Text>
                                                                <Text mt='2' fontSize='sm' color='gray.500'>
                                                                    {video.description}
                                                                </Text>
                                                                <Flex justifyContent="flex-end" alignItems="flex-end" height="100%" mt='4'>
                                                                    <Text fontSize='sm' color='gray.500'>
                                                                        {isValidDate ? format(publishedDate, 'PPP', { locale: ko }) : 'Unknown date'}
                                                                    </Text>
                                                                </Flex>
                                                            </CardBody>
                                                        </Stack>
                                                    </Card>
                                                </LinkBox>
                                            );
                                        })}
                                    </Stack>
                                </>
                            )}



                            {isChunkResponseShowed && ( 
                                <Stack spacing='4' mt='10'>
                                {chunkResponses?.map((chunkResponse) => (
                                    <Card 
                                    key={`${chunkResponse.file_ids}-${chunkResponse.chunk_ids}`}
                                    size='lg'
                                    >
                                    <CardHeader>
                                        <HStack>
                                        <Heading size='md'>
                                            {chunkResponse.title}
                                        </Heading>
                                        <IconButton 
                                            icon={<IoMdDownload/>}
                                            onClick={() => handleGetFileDownload(chunkResponse.file_ids)}
                                        />
                                        </HStack>

                                    </CardHeader>
                                    <CardBody>
                                        <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                            {chunkResponse.Content}
                                        </Markdown> 
                                    </CardBody>
                                </Card>
                                ))}
                            </Stack>
                            )}
                                {isChunkResponseShowed && naverApiResponses.length > 0 && (
                                    <Stack spacing='4' mt='10'>
                                        {naverApiResponses.map((naverApiResponse) => {
                                            let prefix = '';
                                            let mainTitle = naverApiResponse.title;

                                            // ": 네이버 블로그"가 타이틀에 포함되어 있는지 체크
                                            if (naverApiResponse.title.includes(': 네이버 블로그')) {
                                                // ": 네이버 블로그"를 추출하고 나머지 부분을 mainTitle로 설정
                                                const parts = naverApiResponse.title.split(': 네이버 블로그');
                                                prefix = '네이버 블로그';
                                                mainTitle = parts.join('').trim();
                                            } else {
                                                // "네이버 블로그"가 없으면 "네이버 뉴스"로 설정
                                                prefix = '네이버 뉴스';
                                            }

                                            return (
                                                <LinkBox as='article' key={`${naverApiResponse.title}-${naverApiResponse.link}`}>
                                                    <Card 
                                                        size='lg' 
                                                        overflow='hidden' 
                                                        variant='outline' 
                                                        cursor='pointer'
                                                    >
                                                        <CardHeader>
                                                            <Flex alignItems="center">
                                                                <Image 
                                                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Naver_logo_initial.svg/501px-Naver_logo_initial.svg.png?20100828095537" 
                                                                    alt="Naver Logo" 
                                                                    boxSize="40px" 
                                                                    mr="4"
                                                                />
                                                                <Heading size='md'>
                                                                    {prefix && <Text as="span" ml="-8px" mr="8px" fontsize="8px" color="gray.500">{prefix} </Text>}
                                                                    <LinkOverlay href={naverApiResponse.link} isExternal>
                                                                        {mainTitle}
                                                                    </LinkOverlay>
                                                                </Heading>
                                                            </Flex>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Text>
                                                                <LinkOverlay href={naverApiResponse.link} isExternal>
                                                                    {naverApiResponse.description}
                                                                </LinkOverlay>
                                                            </Text>
                                                            <Text align={'right'}>
                                                                {naverApiResponse.pDate}
                                                            </Text>
                                                        </CardBody>
                                                    </Card>
                                                </LinkBox>                            
                                            );
                                        })}
                                    </Stack>
                                )}
                                                    
                        </Container>
                        <Spacer/>
                    </Flex>                             
            </Container>                                        
            
        </Container>
    )


}

export default ChatbotPage;